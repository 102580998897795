
export class OrderDetailModel  {
    public id?: string;
    public orderId: string;
    public goodId: string;
    public num: number;
    public detailPrice: number;
    public amount: number;
    public imageUrl : string
    public userId : string
    public name: string;
    public mva: string;
    public status: string;

    constructor(model: any = {}) {
        this.id = model.id;
        this.orderId = model.orderId;
        this.goodId = model.goodId;
        this.num = model.num;
        this.amount = model.amount;
        this.imageUrl = model.imageUrl;
        this.userId = model.userId;
        this.name = model.name;
        this.mva = model.mva
        this.status = model.status;
    }
}

export class OrderDetailView {
    public name: string;
    public id?: string;
    public orderId: string;
    public goodId: string;
    public num: number;
    public detailPrice: number;
    public amount: number;
    public mva: string;
    public imageUrl: string;
    public status: string;

    constructor(model: any = {}) {
        this.name = model.name;
        this.id = model.id;
        this.orderId = model.orderId;
        this.goodId = model.goodId;
        this.num = model.num;
        this.detailPrice = model.detailPrice;
        this.amount = model.amount;
        this.imageUrl = model.imgageUrl;
        this.mva = model.mva
        this.status = model.status;


    }
}




