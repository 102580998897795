import { Injectable } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductsModel } from '../products/products.model';
import { ProductsService } from './products.service';
import { OrderDetailModel } from '../orders/order-detail/order-detail.model';
import { OrderDetailService } from './order-detail.service';
import { OrderModel } from '../orders/order.model';
import { BaseService } from './base.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { MyError } from './my-error';
import { GlobalUrlProvider } from '../global.provider';

@Injectable({
  providedIn: 'root'
})
export class OrdersService extends BaseService<OrderModel>
{
  subsidy = 0;
  orders: OrderModel[] = [
    new OrderModel({
      id: "oid",
      orderNo: "1618",
      orderDate: "orderDate",
      num: 7,
      amount: 28,
      discount: 0,
      payDiscount: 0,
      pay: "vipps",
      payDate: "2021-04-14 12:09:53",
      userId: "userId",
      status: "start",
      date: "2021-04-14 12:09:53",
      finishDate: "2021-04-14 12:09:53",
      total: 28,
    }), new OrderModel({
      id: "oid2",
      orderNo: "1617",
      orderDate: "orderDate",
      num: 7,
      amount: 28,
      discount: 0,
      payDiscount: 0,
      pay: "vipps",
      payDate: "2021-04-14 12:09:53",
      userId: "userId1",
      status: "start",
      date: "2021-04-14 12:09:53",
      finishDate: "2021-04-14 12:09:53",
      total: 28,
    })
  ]
  constructor(private orderDetail: OrderDetailService, private productService: ProductsService, public db: AngularFirestore,
    public myErr: MyError) {
    super(db, 'orders', myErr);

  }

  setDiscount(amount) {
    console.log("discount",amount)
    this.subsidy = amount;
  }

  getDiscount() {
    return this.subsidy;
  }

  // addModel(order) {
  //   return new Promise((res, rej) => {
  //     order.id = "1"
  //     this.orders.push(order)
  //     res(order)
  //   })

  // }

  getOrderList(userId: string) {
    // return [...this.orders]
    // console.log("getOrderList called", userId)
    let orders = localStorage.getItem('orders');
    let orderDetail = localStorage.getItem('orderDetail');
    // console.log(orders, orderDetail)
    return combineLatest(
      [
        this.db.collection<OrderModel>("/orders", ref => ref.where('userId', '==', userId)).snapshotChanges(),
        this.db.collection<OrderDetailModel>("/orderDetail", ref => ref.where('userId', '==', userId)).snapshotChanges(),
        // this.productService.getList(sessionStorage.getItem('productUrl'))
      ]

      //  this.db.collection<OrderDetailModel>('orderdetail').snapshotChanges()),

    ).pipe(
      map(results => {
        const orderList = results[0].map((action) => {
          // console.log(action)
          const data = action.payload.doc.data();

          return ({ id: action.payload.doc['id'], ...data }) as OrderModel;
        });
        const detailList = results[1].map((action) => {
          // console.log(action)
          const data = action.payload.doc.data();
          return ({ id: action.payload.doc['id'], ...data }) as OrderDetailModel;
        });
        // const goodList = results[2].map((action) => {
        //   return ({ id: action.id, ...action }) as ProductsModel;
        // });
        const dgList = detailList.map(d => {
          // console.log(d)

          // const good = goodList.find(g => g.id === d.goodId);
          // return ({ ...d, ...good });
          return ({ ...d });
        });
        return orderList ? orderList.map(order => {
          return {
            ...order,
            details: dgList.filter(d => d.orderId === order.id)
          };
        }) : [];
      })
        , map(results => results.sort((a, b) => {
          return a.orderNo > b.orderNo ? -1 : 1;
       }))
    );

  }

  getOrderRef() {
    return localStorage.getItem('orders')

  }

  addModel1(item, id) {

    return this.db.doc<OrderModel>(this.path + '/' + id).set(JSON.parse(JSON.stringify(item)));
  }

  getOrderById(id) {

    return this.db.doc<OrderModel>(this.path + '/' + id).get();
  }

  updateModel1(item, id) {

    return this.db.doc<OrderModel>(this.path + '/' + id).update(JSON.parse(JSON.stringify(item)));
  }
}
