import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { OrderDetailModel } from '../orders/order-detail/order-detail.model';
import { OrderModel } from '../orders/order.model';
import { BaseService } from './base.service';
import { MyError } from './my-error';

@Injectable({ providedIn: 'root' })
export class OrderDetailService extends BaseService<OrderDetailModel>{

    orderDetail = [
        new OrderDetailModel({
            id: "p1",
            orderId: "oid",
            goodId: "p1",
            num: "num",
            amount: "12",
            detailPrice: "23"
        }),
        new OrderDetailModel({
            id: "p2",
            orderId: "oid2",
            goodId: "p2",
            num: "num",
            amount: "13",
            detailPrice: "23"
        }),
        new OrderDetailModel({
            id: "p3",
            orderId: "oid",
            goodId: "p3",
            num: "num",
            amount: "12",
            detailPrice: "24"
        })
    ]
    constructor( public db: AngularFirestore,
        public myErr: MyError) {
          super(db,'orderDetail', myErr);
        
      }

    // addModel(item: OrderDetailModel) {
    //     this.orderDetail.push(item)
    //     console.log(this.orderDetail)
    // }

    getOrderDetail() {

        return [...this.orderDetail]
    }

    getList1(orderId: string) {
        // return this.db.collection<OrderDetailModel>('orderdetail',
        //     ref => ref.where('orderId', '==', orderId)).valueChanges()
        //     .pipe(map(detail => detail[0]), mergeMap(detail => {
        //         return this.db.doc<GoodsModel>(`/goods/${detail.goodId}`)
        //             .valueChanges()
        //             .pipe(map(g => [({...g, ...detail})]));
        //     }));
    }

    // getListByParam(param, id) {
    //     return this.orderDetail.filter(od => {
    //         return od[param] === id
    //     })
    // }
}
