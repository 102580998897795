export class OrderModel {
    public id?: string;
    public orderNo: string;
    public orderId: string;
    public orderDate: string;
    public num: number;
    public amount: number;
    public discount: number;
    public payDiscount: number;
    public pay: string;
    public payDate: string;
    public userId: string;
    public user: Object;
    public status: string;
    public date: string;
    public finishDate: string;
    public total: number;
    public paymentStatus: string;
    public systemAddress: string;
    public orderDetail: Array<object>
    public name: string;
    public companyId: string;
    public siteId: string;
    public systemId: string;

    constructor(model: any = {}) {
        this.id = model.id;
        this.orderNo = model.orderNo;
        this.orderId = model.orderId;
        this.orderDate = model.orderDate;
        this.num = model.num;
        this.amount = model.amount;
        this.discount = model.discount;
        this.payDiscount = model.payDiscount;
        this.pay = model.pay;
        this.payDate = model.payDate;
        this.userId = model.userId;
        this.status = model.status;
        this.date = model.date;
        this.finishDate = model.finishDate;
        this.total = model.total;
        this.paymentStatus = model.paymentStatus;
        this.status = model.status;
        this.systemAddress = model.systemAddress;
        this.orderDetail = model.orderDetail
        this.companyId = model.companyId
        this.siteId = model.siteId
        this.systemId = model.systemId

    }
}



