import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmptyScreenService {

  key: {
    title:"",
    subtitle:"",
    message:""
  };

  constructor() {
  }

  setMessage(obj){
    this.key= obj
  }

  getMessage(){
    return this.key
  }

}
